<template>
    <div class="d-flex align-center">
        <img src="@/assets/logo/LoginLogoC5.06.svg" class="al-logo">
        <div class="auth-logo-title">
            <template v-if="$i18n.locale == 'kk'">
                <div class="alt-main">{{$t("города_Атырау")}}</div>
                <div class="alt-sub">{{$t("Портал_городских_услуг")}}</div>                
            </template> 
            <template v-else>
                <div class="alt-sub marg-b-10">{{$t("Портал_городских_услуг")}}</div>
                <div class="alt-main mb-0">{{$t("города_Атырау")}}</div>                
            </template>
        </div>
    </div>
</template>
<script>
export default
{
    name: 'LoginLogoC5.06',
}
</script>